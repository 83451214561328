import { axiosWithJWT } from '@/api/axiosWithJWT';

export const getUserProfile = async (_) => {
  const { data } = await axiosWithJWT.get('/api/User/Get');

  return data;
};

export const editUserProfile = async (_, user) => {
  await axiosWithJWT.put(`/api/User/Update/${user.id}`, user);
};
