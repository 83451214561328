export default [
  {
    path: '/profile',
    name: 'user-profile',
    component: () => import('@/modules/user/views/ProfileView.vue'),
    meta: {
      pageTitle: 'Profile',
      breadcrumb: [{ text: 'Profile', active: true }],
    },
  },
];
