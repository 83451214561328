import store from '@/store';
import axios from 'axios';
import router from '@/router';

axios.interceptors.request.use(
  async (config) => {
    const token = store.getters['authentication/accessToken'];

    if (token) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${token}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config } = error;

    if (error.response.status === 401 && !config._retry) {
      config._retry = true;

      const refreshToken = store.getters['authentication/refreshToken'];
      const user = store.getters['authentication/authenticatedUser'];

      const username = user !== null ? user.username : null;

      try {
        const token = await store.dispatch('authentication/refreshTokens', {
          username,
          refreshToken,
        });

        config.headers = {
          ...config.headers,
          authorization: `Bearer ${token}`,
        };
      } catch (error) {
        store.dispatch('authentication/signOut');
        router.push({ name: 'authentication-login' }).catch(() => {}); // Catch avoid display error on console.
      }

      return axios(config);
    }

    return Promise.reject(error);
  }
);

export const axiosWithJWT = axios;
