export const storeTaxi = (state, taxi) => {
  state.taxi = { ...taxi };
};

export const storeTaxiList = (state, taxiList) => {
  state.taxiList = [...taxiList];
};

export const storeTaxiBalance = (state, newBalance) => {
  const taxi = { ...state.taxi };

  state.taxi = { ...taxi, balance: newBalance };
};

export const storeTaxiSignatureData = (state, newSignatureData) => {
  state.taxiSignatureData = { ...newSignatureData };
};

export const resetTaxiSignatureData = (state) => {
  state.taxiSignatureData = { signatureSrc: null };
};
