export default [
  {
    path: '/point-of-interest',
    name: 'poi-list',
    component: () => import('@/modules/poi/views/PoiList.vue'),
    meta: {
      pageTitle: 'Poi',
      breadcrumb: [
        {
          text: 'Point of Interest',
          active: true,
        },
      ],
    },
  },
];
