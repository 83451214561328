import { axiosWithJWT } from '@/api/axiosWithJWT';

export const create = async ({ commit, getters }, vehicle) => {
  const { data } = await axiosWithJWT.post('/api/TaxiVehicle/Create', vehicle);

  const vehicles = [...getters['vehicles'], data];
  commit('storeVehicles', vehicles);

  if (data.isMainVehicle) commit('storeMainVehicle', data);

  return data;
};

export const getVehiclesByTaxi = async ({ commit }, taxiId) => {
  const { data } = await axiosWithJWT.get(
    `/api/TaxiVehicle/GetByTaxi/${taxiId}`
  );

  commit('storeVehicles', data);

  return data;
};

export const update = async ({ commit, getters }, vehicle) => {
  await axiosWithJWT.put(`/api/TaxiVehicle/Update/${vehicle.id}`, vehicle);

  const mainVehicle = getters['mainVehicle'];
  if (mainVehicle.id === vehicle.id && !vehicle.isMainVehicle) {
    commit('resetMainVehicle');
  } else if (vehicle.isMainVehicle) commit('storeMainVehicle', vehicle);
};

export const remove = async ({ commit }, vehicle) => {
  await axiosWithJWT.delete(`/api/TaxiVehicle/Delete/${vehicle.id}`);

  commit('removeVehicle', vehicle.id);
  if (vehicle.isMainVehicle) commit('resetMainVehicle');
};
