export default [
  {
    path: '/frontier-tour/add',
    name: 'frontier-tour-add',
    component: () =>
      import('@/modules/frontier-tour/views/FrontierTourForm.vue'),
    meta: {
      pageTitle: 'Frontier Tour',
      breadcrumb: [
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/frontier-tour/add-multiple',
    name: 'frontier-tour-add-multiple',
    component: () =>
      import('@/modules/frontier-tour/views/FrontierTourAddMultiple.vue'),
    meta: {
      pageTitle: 'Frontier Tours',
      breadcrumb: [
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/frontier-tour/list',
    name: 'frontier-tour-list',
    component: () =>
      import('@/modules/frontier-tour/views/FrontierTourList.vue'),
    meta: {
      pageTitle: 'Frontier Tours',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/frontier-tour/edit/:id',
    name: 'frontier-tour-edit',
    component: () =>
      import('@/modules/frontier-tour/views/FrontierTourForm.vue'),
    meta: {
      pageTitle: 'Frontier Tour',
      breadcrumb: [
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/frontier-tour/:id',
    name: 'frontier-tour-detail',
    component: () =>
      import('@/modules/frontier-tour/views/FrontierTourDetail.vue'),
    meta: {
      pageTitle: 'Frontier Tour',
      breadcrumb: [
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },
];
