export default [
  {
    path: '/rate/crew',
    name: 'rate-crew',
    component: () => import('@/modules/rate/views/CrewList.vue'),
    meta: {
      pageTitle: 'Crew Rates',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/rate/gib-air',
    name: 'rate-gib-air',
    component: () => import('@/modules/rate/views/GibAirList.vue'),
    meta: {
      pageTitle: 'Gib Air Rates',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/rate/wait-time',
    name: 'rate-wait-time',
    component: () => import('@/modules/rate/views/WaitTimeList.vue'),
    meta: {
      pageTitle: 'Wait Time Rates',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
];
