export default [
  {
    path: '/companies/list',
    name: 'company-list',
    component: () => import('@/modules/company/views/CompanyList.vue'),
    meta: {
      pageTitle: 'Companies',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/companies/transactions',
    name: 'companies-transaction',
    component: () =>
      import('@/modules/company/views/CompanyTransactionList.vue'),
    meta: {
      pageTitle: 'Companies Transactions',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/companies/:id/transactions',
    name: 'company-transaction-history',
    component: () =>
      import('@/modules/company/views/CompanyTransactionsHistory.vue'),
    meta: {
      pageTitle: 'Companies',
      breadcrumb: [
        {
          text: 'List',
          to: { name: 'company-list' },
        },
        {
          text: 'Company Transactions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/companies/:id',
    name: 'company-details',
    component: () => import('@/modules/company/views/CompanyDetail.vue'),
    meta: {
      pageTitle: 'Companies',
      breadcrumb: [
        {
          text: 'List',
          to: { name: 'company-list' },
        },
        {
          text: 'Company Details',
          active: true,
        },
      ],
    },
  },
];
