import { axiosWithJWT } from '@/api/axiosWithJWT';

export const getAnnualStatistics = async (_, year) => {
  const { data } = await axiosWithJWT.get(
    '/api/Dashboard/GetAnnualStatistics',
    { params: { year } }
  );
  return data;
};

export const getBookingNumberInAMonth = async (_, date) => {
  const { data } = await axiosWithJWT.get(
    '/api/Dashboard/GetBookingNumberInAMonth',
    { params: { date } }
  );
  return data;
};

export const getNumOfBookingsByDay = async (_, date) => {
  const { data } = await axiosWithJWT.get(
    '/api/Dashboard/GetNumOfBookingsByDay',
    { params: { date } }
  );
  return data;
};

export const getAnnualCompaniesPaymentsSummary = async (_, year) => {
  const { data } = await axiosWithJWT.get(
    '/api/Dashboard/GetAnnualCompaniesPaymentsSummary',
    { params: { year } }
  );
  return data;
};

export const getTaxiBalanceList = async (_, filters) => {
  const { data } = await axiosWithJWT.get('/api/Dashboard/GetTaxiBalanceList', {
    params: filters,
  });
  return data;
};
