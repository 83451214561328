import { axiosWithJWT } from '@/api/axiosWithJWT';

export const getList = async ({ commit }, filter) => {
  const { data } = await axiosWithJWT.get('/api/Taxi/GetList', {
    params: filter,
  });

  commit('storeTaxiList', data.taxis);

  return data;
};

export const get = async ({ commit }, id) => {
  const { data } = await axiosWithJWT.get(`/api/Taxi/Get/${id}`);

  commit('storeTaxi', data);

  return data;
};

export const downloadAccountStatement = async (_, { taxiId, params }) => {
  return await axiosWithJWT.post(
    `/api/Taxi/DownloadStatement/${taxiId}`,
    params,
    {
      responseType: 'blob',
    }
  );
};

export const sendAccountStatement = async (_, { taxiId, params }) => {
  await axiosWithJWT.post(`/api/Taxi/SendStatement/${taxiId}`, params);
};

export const create = async (_, taxi) => {
  const { data } = await axiosWithJWT.post('/api/Taxi/Create', taxi);
  return data;
};

export const update = async (_, taxi) => {
  await axiosWithJWT.put(`/api/Taxi/Update/${taxi.id}`, taxi);
};

export const remove = async (_, taxiId) => {
  await axiosWithJWT.delete(`/api/Taxi/Delete/${taxiId}`);
};

export const modifySignatureData = ({ commit }, newSignature) => {
  commit('storeTaxiSignatureData', newSignature);
};
