import { axiosWithJWT } from '@/api/axiosWithJWT';

export const getList = async ({ commit }, filter) => {
  const { data } = await axiosWithJWT.get('/api/TourType/GetList', {
    params: filter,
  });

  commit('storeTourTypes', data.tourTypes);

  return data;
};

export const get = async (_, id) => {
  const { data } = await axiosWithJWT.get(`/api/TourType/Get/${id}`);
  return data;
};

export const create = async (_, tourType) => {
  const { data } = await axiosWithJWT.post('/api/TourType/Create', tourType);
  return data;
};

export const update = async (_, tourType) => {
  await axiosWithJWT.put(`/api/TourType/Update/${tourType.id}`, tourType);
};

export const remove = async (_, id) => {
  await axiosWithJWT.delete(`/api/TourType/Delete/${id}`);
};
