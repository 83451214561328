import { axiosWithJWT } from '@/api/axiosWithJWT';

export const create = async (_, gibAirRate) => {
  const { data } = await axiosWithJWT.post(
    '/api/RateGibAir/Create',
    gibAirRate
  );
  return data;
};

export const getList = async () => {
  const { data } = await axiosWithJWT.get('/api/RateGibAir/GetList');
  return data;
};

export const getGibAirRate = async ({ commit }, id) => {
  const { data } = await axiosWithJWT.get(`/api/RateGibAir/Get/${id}`);

  commit('storeGibAirRate', data);

  return data;
};

export const remove = async (_, gibAirRate) => {
  await axiosWithJWT.delete(`/api/RateGibAir/Delete/${gibAirRate.id}`);
};

export const update = async (_, gibAirRate) => {
  await axiosWithJWT.put(`/api/RateGibAir/Update/${gibAirRate.id}`, gibAirRate);
};
