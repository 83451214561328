import { axiosWithJWT } from '@/api/axiosWithJWT';

export const create = async (_, transaction) => {
  const { data } = await axiosWithJWT.post(
    '/api/AccountTransaction/Create',
    transaction
  );
  return data;
};

export const getTransactionsByAccount = async (
  { commit },
  { accountId, params }
) => {
  const { data } = await axiosWithJWT.get(
    `/api/AccountTransaction/GetListByAccount/${accountId}`,
    { params }
  );

  commit('storeLastTransactions', data.transactions);

  return data;
};

export const getTransactionStatus = async () => {
  const { data } = await axiosWithJWT.get('/api/TransactionStatus/GetList');
  return data;
};

export const getTransactionTypes = async () => {
  const { data } = await axiosWithJWT.get('/api/TransactionType/GetList');
  return data;
};

export const getTransactions = async (_, { params }) => {
  const { data } = await axiosWithJWT.get('/api/AccountTransaction/GetList', {
    params,
  });
  return data;
};

export const updateTransaction = async (_, transaction) => {
  await axiosWithJWT.put(
    `/api/AccountTransaction/Update/${transaction.id}`,
    transaction
  );
};

export const cancelTransaction = async (_, {transactionId, description}) => {
  await axiosWithJWT.put(
    `/api/AccountTransaction/CancelTransaction/${transactionId}`,
    { description }
  );
};