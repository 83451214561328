import axios from 'axios';

export const signIn = async ({ commit }, credentials) => {
  const { data } = await axios.post('/api/Auth/SignIn', credentials);
  const { token, refreshToken, username, firstName, lastName } = data;

  commit('storeUserInfo', {
    token,
    refreshToken,
    username,
    firstName,
    lastName,
  });

  return data;
};

export const forgotPassword = async (_, email) => {
  await axios.post('/api/Auth/ForgotPassword', email);
};

export const resetPassword = async (_, body) => {
  await axios.post('/api/Auth/ResetPassword', body);
};

export const refreshTokens = async ({ commit }, params) => {
  const { data } = await axios.post('/api/Auth/RefreshToken', params);
  const { token, refreshToken } = data;

  commit('storeUserInfo', { token, refreshToken });

  return token;
};

export const signOut = ({ commit }) => {
  commit('removeUserInfo');
};
