import { axiosWithJWT } from '@/api/axiosWithJWT';

export const getList = async ({ commit }, filter) => {
  const { data } = await axiosWithJWT.get('/api/StaffMember/GetList', {
    params: filter,
  });

  commit('storeStaffList', data.staff);

  return data;
};

export const create = async (_, staff) => {
  const { data } = await axiosWithJWT.post('/api/StaffMember/Create', staff);
  return data;
};

export const update = async (_, staff) => {
  await axiosWithJWT.put(`/api/StaffMember/Update/${staff.id}`, staff);
};

export const remove = async (_, staffId) => {
  await axiosWithJWT.delete(`/api/StaffMember/Delete/${staffId}`);
};
