import { axiosWithJWT } from '@/api/axiosWithJWT';

export const create = async (_, waitTimeRate) => {
  const { data } = await axiosWithJWT.post(
    '/api/RateWaitTime/Create',
    waitTimeRate
  );
  return data;
};

export const getList = async ({ commit }) => {
  const { data } = await axiosWithJWT.get('/api/RateWaitTime/GetList');

  commit('storeWaitTimeRateList', data);

  return data;
};

export const remove = async (_, waitTimeRate) => {
  await axiosWithJWT.delete(`/api/RateWaitTime/Delete/${waitTimeRate.id}`);
};

export const update = async (_, waitTimeRate) => {
  await axiosWithJWT.put(
    `/api/RateWaitTime/Update/${waitTimeRate.id}`,
    waitTimeRate
  );
};
