export const storeTour = (state, tour) => {
  state.tour = { ...tour };
};

export const resetTour = (state) => {
  state.tour = {
    id: null,
    accountCommission: 0.0,
    dateAddedUtc: null,
    isVoucherNumOverwritten: false,
    juraAccCommission: 0.0,
    notes: null,
    taxi: null,
    taxiCommission: 0.0,
    taxiId: null,
    tourAdjustmentFee: 0.0,
    accountAdjustmentFee: 0.0,
    juraAdjustmentFee: 0.0,
    tourDateUtc: null,
    tourTotalFee: 0.0,
    tourType: null,
    tourTypeId: null,
    vehicle: null,
    vehicleId: null,
    voucherNum: null,
  };
};
