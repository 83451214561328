import { axiosWithJWT } from '@/api/axiosWithJWT';

export const create = async (_, poi) => {
  const { data } = await axiosWithJWT.post('/api/PointOfInterest/Create', poi);
  return data;
};

export const getList = async ({ commit }, filter) => {
  const { data } = await axiosWithJWT.get('/api/PointOfInterest/GetList', {
    params: filter,
  });

  commit('storePoiList', data.poi);
  return data;
};

export const remove = async (_, poi) => {
  await axiosWithJWT.delete(`/api/PointOfInterest/Delete/${poi.id}`);
};

export const update = async (_, poi) => {
  await axiosWithJWT.put(`/api/PointOfInterest/Update/${poi.id}`, poi);
};
