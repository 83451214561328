export const storePost = (state, post) => {
  state.post = { ...post };
};

export const resetPost = (state) => {
  state.post = {
    account: null,
    accountCommission: 0.0,
    accountId: null,
    company: null,
    companyId: null,
    dateAddedUtc: null,
    id: null,
    notes: null,
    postTotalFee: 0.0,
    postTaxis: [{ taxi: null, taxiRate: 0.0 }],
  };
};
