import { axiosWithJWT } from '@/api/axiosWithJWT';

export const create = async (_, report) => {
  const { data } = await axiosWithJWT.post('/api/CompanyReport/Create', report);

  return data;
};

export const getReportList = async ({ commit }, companyId) => {
  const { data } = await axiosWithJWT.get(
    `/api/CompanyReport/GetList/${companyId}`
  );

  commit('storeReports', data.reports);

  return data;
};

export const getReportTypes = async (_) => {
  const { data } = await axiosWithJWT.get(`/api/ReportType/GetList`);

  return data;
};

export const update = async (_, report) => {
  await axiosWithJWT.put(`/api/CompanyReport/Update/${report.id}`, report);
};
