import * as getters from './getters';
import * as mutations from './mutations';
import state from './state';

const tourMultipleModule = {
  namespaced: true,
  getters,
  mutations,
  state,
};

export default tourMultipleModule;
