export const storeMainAccount = (state, account) => {
  state.mainAccount = { ...account };
};

export const storeAccounts = (state, accounts) => {
  state.accounts = [...accounts];
};

export const removeAccount = (state, id) => {
  const index = state.accounts.findIndex((account) => account.id === id);
  state.accounts.splice(index, 1);
};

export const resetAccountList = (state) => {
  state.accounts = [];
};

export const resetMainAccount = (state) => {
  state.mainAccount = {
    bankAccountNum: null,
    bankName: null,
    bankSortCode: null,
    id: null,
    isActive: null,
    taxiId: null,
  };
};
