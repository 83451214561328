export default [
  {
    path: '/standing-order-types',
    name: 'standing-order-type-list',
    component: () =>
      import('@/modules/standing-order-type/views/StandingOrderTypeList.vue'),
    meta: {
      pageTitle: 'Standing Order Type',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
];
