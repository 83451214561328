import { axiosWithJWT } from '@/api/axiosWithJWT';

export const getTourAttachmentList = async ({ commit }, tourId) => {
  const { data } = await axiosWithJWT.get(
    `/api/TourAttachment/GetTourList/${tourId}`
  );
  commit('storeTourAttachments', data.attachments);
  return data;
};

export const uploadFiles = async (_, { tourId, tourAttachments }) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const formData = new FormData();

  tourAttachments.forEach((file) => {
    formData.append('files', file);
  });

  await axiosWithJWT.post(
    `/api/TourAttachment/UploadFiles/${tourId}`,
    formData,
    {
      headers,
    }
  );
};

export const deleteFile = async (_, id) => {
  await axiosWithJWT.delete(`/api/TourAttachment/Delete/${id}`);
};

export const downloadFile = async (_, id) => {
  return await axiosWithJWT.get(`/api/TourAttachment/Get/${id}`, {
    responseType: 'blob',
  });
};
