export default [
  {
    path: '/staff',
    name: 'staff-list',
    component: () => import('@/modules/staff/views/StaffList.vue'),
    meta: {
      pageTitle: 'Staff',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
];
