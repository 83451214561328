import { axiosWithJWT } from '@/api/axiosWithJWT';

export const create = async ({ commit, getters }, standingOrder) => {
  const { data } = await axiosWithJWT.post(
    '/api/StandingOrder/Create',
    standingOrder
  );

  return data;
};

export const getTaxiList = async ({ commit }, taxiId) => {
  const { data } = await axiosWithJWT.get(
    `/api/StandingOrder/GetTaxiList/${taxiId}`
  );

  commit('storeStandingOrders', data);

  return data;
};

export const update = async (_, standingOrder) => {
  await axiosWithJWT.put(
    `/api/StandingOrder/Update/${standingOrder.id}`,
    standingOrder
  );
};
