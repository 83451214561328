export default () => ({
  post: {
    account: null,
    accountCommission: null,
    accountId: null,
    company: null,
    companyId: null,
    dateAddedUtc: null,
    id: null,
    notes: null,
    postTotalFee: 0.0,
    postTaxis: [{ taxi: null, taxiRate: 0.0 }],
  },
});
