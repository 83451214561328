export default () => ({
  mainVehicle: {
    id: null,
    isMainVehicle: null,
    manufacturer: null,
    model: null,
    numSeats: null,
    regNum: null,
    taxiId: null,
    wheelchairFriendly: null,
    year: null,
  },
  vehicles: [],
});
