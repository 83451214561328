import { axiosWithJWT } from '@/api/axiosWithJWT';

export const create = async (_, tour) => {
  const { data } = await axiosWithJWT.post('/api/Tour/Create', tour);
  return data;
};

export const createMultiple = async (_, tour) => {
  const { data } = await axiosWithJWT.post('/api/Tour/CreateMultiple', tour);
  return data;
};

export const getTour = async ({ commit }, id) => {
  const { data } = await axiosWithJWT.get(`/api/Tour/Get/${id}`);

  commit('storeTour', data);

  return data;
};

export const downloadTour = async (_, tourId) => {
  return await axiosWithJWT.get(`/api/Tour/Download/${tourId}`, {
    responseType: 'blob',
  });
};

export const getTours = async (_, filter) => {
  const { data } = await axiosWithJWT.get('/api/Tour/GetList', {
    params: filter,
  });
  return data;
};

export const update = async (_, tour) => {
  const { data } = await axiosWithJWT.put(`/api/Tour/Update/${tour.id}`, tour);
  return data;
};

export const cancelTour = async (_, tourId) => {
  await axiosWithJWT.put(`/api/Tour/CancelTour/${tourId}`);
};

export const remove = async (_, id) => {
  await axiosWithJWT.delete(`/api/Tour/Delete/${id}`);
};

export const resetVouchersNum = async (_) => {
  await axiosWithJWT.put(`/api/Tour/ResetVouchersNum`);
};
