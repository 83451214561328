import { axiosWithJWT } from '@/api/axiosWithJWT';

export const getBalanceList = async (_, params) => {
  return await axiosWithJWT.post('/api/Statistic/GetBalance', params, {
    responseType: 'blob',
  });
};

export const getstandingOrderList = async (_, params) => {
  return await axiosWithJWT.post(
    '/api/Statistic/GetTaxiStandingOrder',
    params,
    {
      responseType: 'blob',
    }
  );
};
