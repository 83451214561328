import { axiosWithJWT } from '@/api/axiosWithJWT';

export const create = async ({ commit, getters }, bankAccount) => {
  const { data } = await axiosWithJWT.post(
    '/api/TaxiBankAccount/Create',
    bankAccount
  );

  const accounts = [...getters['accounts'], data];
  commit('storeAccounts', accounts);

  if (data.isMain) commit('storeMainAccount', data);

  return data;
};

export const getAccountByTaxi = async ({ commit }, taxiId) => {
  const { data } = await axiosWithJWT.get(
    `/api/TaxiBankAccount/GetByTaxi/${taxiId}`
  );

  commit('storeAccounts', data);

  return data;
};

export const update = async ({ commit, getters }, account) => {
  await axiosWithJWT.put(`/api/TaxiBankAccount/Update/${account.id}`, account);

  const mainAccount = getters['mainAccount'];
  if (mainAccount === account.id && !account.isMain) {
    commit('resetMainAccount');
  } else if (account.isMain) commit('storeMainAccount', account);
};

export const remove = async ({ commit }, account) => {
  await axiosWithJWT.delete(`/api/TaxiBankAccount/Delete/${account.id}`);

  commit('removeAccount', account.id);
  if (account.isMain) commit('resetMainAccount');
};
