import { axiosWithJWT } from '@/api/axiosWithJWT';

export const getPostAttachmentList = async ({ commit }, postId) => {
  const { data } = await axiosWithJWT.get(
    `/api/PostAttachment/GetPostList/${postId}`
  );
  commit('storePostAttachments', data.attachments);
  return data;
};

export const uploadFiles = async (_, { postId, postAttachments }) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const formData = new FormData();

  postAttachments.forEach((file) => {
    formData.append('files', file);
  });

  await axiosWithJWT.post(
    `/api/PostAttachment/UploadFiles/${postId}`,
    formData,
    {
      headers,
    }
  );
};

export const deleteFile = async (_, id) => {
  await axiosWithJWT.delete(`/api/PostAttachment/Delete/${id}`);
};

export const downloadFile = async (_, id) => {
  return await axiosWithJWT.get(`/api/PostAttachment/Get/${id}`, {
    responseType: 'blob',
  });
};
