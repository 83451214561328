export default () => ({
  mainAccount: {
    bankAccountNum: null,
    bankName: null,
    bankSortCode: null,
    id: null,
    isActive: null,
    taxiId: null,
  },
  accounts: [],
});
