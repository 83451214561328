export const storeUserInfo = (
  state,
  { token, refreshToken, username, firstName, lastName }
) => {
  if (token) {
    state.token = token;
    localStorage.setItem('token', token);
  }

  if (refreshToken) {
    state.refreshToken = refreshToken;
    localStorage.setItem('refreshToken', refreshToken);
  }

  if (username && firstName && lastName) {
    state.user.username = username;
    state.user.firstName = firstName;
    state.user.lastName = lastName;
    localStorage.setItem('user', JSON.stringify(state.user));
  }
};

export const removeUserInfo = (state) => {
  state.token = null;
  localStorage.removeItem('token');

  state.refreshToken = null;
  localStorage.removeItem('refreshToken');

  state.user = {};
  localStorage.removeItem('user');
};
