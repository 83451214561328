export default [
  {
    path: '/booking/list',
    name: 'booking-list',
    component: () => import('@/modules/booking/views/BookingList.vue'),
    meta: {
      pageTitle: 'Booking',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/booking/crew',
    name: 'booking-crew',
    component: () => import('@/modules/booking/views/AddBooking.vue'),
    props: { bookingType: 'crew' },
    meta: {
      pageTitle: 'Booking',
      breadcrumb: [
        {
          text: 'Crew',
          active: true,
        },
      ],
    },
  },
  {
    path: '/booking/pax',
    name: 'booking-pax',
    component: () => import('@/modules/booking/views/AddBooking.vue'),
    props: { bookingType: 'pax' },
    meta: {
      pageTitle: 'Booking',
      breadcrumb: [
        {
          text: 'Pax',
          active: true,
        },
      ],
    },
  },
  {
    path: '/booking/gha',
    name: 'booking-gha',
    component: () => import('@/modules/booking/views/AddBooking.vue'),
    props: { bookingType: 'gha' },
    meta: {
      pageTitle: 'Booking',
      breadcrumb: [
        {
          text: 'GHA',
          active: true,
        },
      ],
    },
  },
  {
    path: '/booking/airport-pick-up',
    name: 'booking-airport-pick-up',
    component: () => import('@/modules/booking/views/AddBooking.vue'),
    props: { bookingType: 'airport-pick-up' },
    meta: {
      pageTitle: 'Booking',
      breadcrumb: [
        {
          text: 'Airport Pick Up',
          active: true,
        },
      ],
    },
  },
  {
    path: '/booking/gib-air',
    name: 'booking-gib-air',
    component: () => import('@/modules/booking/views/AddBooking.vue'),
    props: { bookingType: 'gib-air' },
    meta: {
      pageTitle: 'Booking',
      breadcrumb: [
        {
          text: 'Gib Air',
          active: true,
        },
      ],
    },
  },
  {
    path: '/booking/cancelled',
    name: 'booking-cancelled',
    component: () => import('@/modules/booking/views/AddBooking.vue'),
    props: { bookingType: 'cancelled' },
    meta: {
      pageTitle: 'Booking',
      breadcrumb: [
        {
          text: 'Cancelled Vouchers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/booking/:id',
    name: 'booking-detail',
    component: () => import('@/modules/booking/views/BookingDetail.vue'),
    meta: {
      pageTitle: 'Booking',
      breadcrumb: [
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/booking/edit/:id',
    name: 'booking-edit',
    component: () => import('@/modules/booking/views/EditBooking.vue'),
    meta: {
      pageTitle: 'Booking',
      breadcrumb: [
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
];
