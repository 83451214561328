export const storeTour = (state, tour) => {
  state.tour = { ...tour };
};

export const resetTour = (state) => {
  state.tour = {
    accountCommission: 0.0,
    juraAccCommission: 0.0,
    passengerNum: 0,
    taxis: [{ voucherNum: null, taxi: null, taxiRate: 0.0, vehicle: null }],
    tourDateUtc: null,
    tourTotalFee: 0.0,
    taxiTotalFee: 0.0,
  };
};
