export const storeReports = (state, reports) => {
  const result = reports.map((report) => {
    let transactionStatusIds = [];
    let bookingStatusIds = [];
    let postStatusIds = [];

    if (report.reportScheduleOptions.length > 0) {
      report.reportScheduleOptions.forEach((opt) => {
        if (opt.bookingStatusId) {
          bookingStatusIds.push(opt.bookingStatusId);
        }
        if (opt.postStatusId) {
          postStatusIds.push(opt.postStatusId);
        }
        if (opt.transactionStatusId) {
          transactionStatusIds.push(opt.transactionStatusId);
        }
      });
    }

    report.transactionStatusIds = transactionStatusIds;
    report.bookingStatusIds = bookingStatusIds;
    report.postStatusIds = postStatusIds;
    return report;
  });

  state.reports = [...result];
};
