import { axiosWithJWT } from '@/api/axiosWithJWT';

export const create = async (_, bookingFeeType) => {
  const { data } = await axiosWithJWT.post(
    '/api/BookingFeeType/Create',
    bookingFeeType
  );
  return data;
};

export const getList = async ({ commit }) => {
  const { data } = await axiosWithJWT.get('/api/BookingFeeType/GetList');
  commit('storeBookingFeeTypeList', data);
  return data;
};

export const update = async (_, bookingFeeType) => {
  await axiosWithJWT.put(
    `/api/BookingFeeType/Update/${bookingFeeType.id}`,
    bookingFeeType
  );
};
