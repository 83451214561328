export const storeMainVehicle = (state, vehicle) => {
  state.mainVehicle = { ...vehicle };
};

export const storeVehicles = (state, vehicles) => {
  state.vehicles = [...vehicles];
};

export const removeVehicle = (state, id) => {
  const index = state.vehicles.findIndex((vehicle) => vehicle.id === id);
  state.vehicles.splice(index, 1);
};

export const resetVehicleList = (state) => {
  state.vehicles = [];
};

export const resetMainVehicle = (state) => {
  state.mainVehicle = {
    id: null,
    isMainVehicle: null,
    manufacturer: null,
    model: null,
    numSeats: null,
    regNum: null,
    taxiId: null,
    wheelchairFriendly: null,
    year: null,
  };
};
