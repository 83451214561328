import * as actions from './actions';

const juraCardModule = {
  namespaced: true,
  actions,
  getters: {},
  mutations: {},
  state: {},
};

export default juraCardModule;
