export const authenticatedUser = (state) =>
  state.user.username === null
    ? JSON.parse(localStorage.getItem('user'))
    : state.user;

export const accessToken = (state) =>
  state.token === null ? localStorage.getItem('token') : state.token;

export const refreshToken = (state) =>
  state.refreshToken === null
    ? localStorage.getItem('refreshToken')
    : state.refreshToken;
