import { axiosWithJWT } from '@/api/axiosWithJWT';

export const create = async (_, crewRate) => {
  const { data } = await axiosWithJWT.post('/api/RateCrew/Create', crewRate);
  return data;
};

export const getList = async ({ commit }) => {
  const { data } = await axiosWithJWT.get('/api/RateCrew/GetList');
  commit('storeCrewRateList', data);
  return data;
};

export const remove = async (_, crewRate) => {
  await axiosWithJWT.delete(`/api/RateCrew/Delete/${crewRate.id}`);
};

export const update = async (_, crewRate) => {
  await axiosWithJWT.put(`/api/RateCrew/Update/${crewRate.id}`, crewRate);
};
