import { computed } from '@vue/composition-api';
import Vue from 'vue';
import VueRouter from 'vue-router';

import AccountRouter from '@/modules/account/router';
import AuthenticationRoutes from '@/modules/authentication/router';
import BookingFeeType from '@/modules/booking-settings/router';
import BookingRoutes from '@/modules/booking/router';
import CompanyRoutes from '@/modules/company/router';
import FrontierTourRouter from '@/modules/frontier-tour/router';
import FrontierTourSettingsRouter from '@/modules/frontier-tour-settings/router';
import PoiRouter from '@/modules/poi/router';
import PostRouter from '@/modules/post/router';
import RateRouter from '@/modules/rate/router';
import SettingRouter from '@/modules/setting/router';
import StaffRouter from '@/modules/staff/router';
import StandingOrderTypeRouter from '@/modules/standing-order-type/router';
import StatisticRouter from '@/modules/statistic/router';
import TaxiRouter from '@/modules/taxi/router';
import UserRouter from '@/modules/user/router';
import DashboardRouter from '@/modules/dashboard/router';

Vue.use(VueRouter);

const routeData = Vue.observable({ params: {}, query: {} });

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...DashboardRouter,
    ...AccountRouter,
    ...AuthenticationRoutes,
    ...BookingFeeType,
    ...BookingRoutes,
    ...CompanyRoutes,
    ...FrontierTourRouter,
    ...FrontierTourSettingsRouter,
    ...PoiRouter,
    ...PostRouter,
    ...RateRouter,
    ...SettingRouter,
    ...StaffRouter,
    ...StandingOrderTypeRouter,
    ...StatisticRouter,
    ...TaxiRouter,
    ...UserRouter,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((route) => {
  routeData.params = route.params;
  routeData.query = route.query;
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export const useParams = () => {
  return computed(() => ({ ...routeData }));
};

export default router;
