export default [
  {
    path: '/accounts/list',
    name: 'account-list',
    component: () => import('@/modules/account/views/AccountList.vue'),
    meta: {
      pageTitle: 'Accounts',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounts/transactions',
    name: 'account-transaction',
    component: () =>
      import('@/modules/account/views/AccountTransactionList.vue'),
    meta: {
      pageTitle: 'Accounts Transactions',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounts/:id/transactions',
    name: 'account-transaction-history',
    component: () =>
      import('@/modules/account/views/AccountTransactionHistoryList.vue'),
    meta: {
      pageTitle: 'Accounts',
      breadcrumb: [
        {
          text: 'List',
          to: { name: 'account-list' },
        },
        {
          text: 'Account Transactions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/accounts/:id',
    name: 'account-details',
    component: () => import('@/modules/account/views/AccountDetail.vue'),
    meta: {
      pageTitle: 'Accounts',
      breadcrumb: [
        {
          text: 'List',
          to: { name: 'account-list' },
        },
        {
          text: 'Account Details',
          active: true,
        },
      ],
    },
  },
];
