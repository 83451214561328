export default [
  {
    path: '/post/list',
    name: 'post-list',
    component: () => import('@/modules/post/views/PostList.vue'),
    meta: {
      pageTitle: 'Post',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/post/add',
    name: 'post-add',
    component: () => import('@/modules/post/views/PostForm.vue'),
    meta: {
      pageTitle: 'Post',
      breadcrumb: [
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/post/edit/:id',
    name: 'post-edit',
    component: () => import('@/modules/post/views/PostForm.vue'),
    meta: {
      pageTitle: 'Post',
      breadcrumb: [
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/post/:id',
    name: 'post-detail',
    component: () => import('@/modules/post/views/PostDetail.vue'),
    meta: {
      pageTitle: 'Post',
      breadcrumb: [
        {
          text: 'Details',
          active: true,
        },
      ],
    },
  },
];
