import { axiosWithJWT } from '@/api/axiosWithJWT';

export const create = async (_, juraCard) => {
  const { data } = await axiosWithJWT.post(
    '/api/TaxiJuraCard/Create',
    juraCard
  );
  return data;
};

export const addCredit = async (_, params) => {
  const { data } = await axiosWithJWT.post(
    '/api/TaxiJuraCard/AddCredit',
    { ...params }
  );
  return data;
};

export const getJuraCashlessCardDetails = async (_, cardNum) => {
  const { data } = await axiosWithJWT.get(
    `/api/TaxiJuraCard/GetJuraCashlessCardDetails/${cardNum}`
  );
  return data;
};

export const update = async (_, juraCard) => {
  await axiosWithJWT.put(`/api/TaxiJuraCard/Update/${juraCard.id}`, juraCard);
};

export const remove = async (_, juraCardId) => {
  await axiosWithJWT.delete(`/api/TaxiJuraCard/Delete/${juraCardId}`);
};
