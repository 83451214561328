import { axiosWithJWT } from '@/api/axiosWithJWT';

export const create = async (_, booking) => {
  const { data } = await axiosWithJWT.post('/api/Booking/Create', booking);
  return data;
};

export const getBooking = async ({ commit }, bookingId) => {
  const { data } = await axiosWithJWT.get(`/api/Booking/Get/${bookingId}`);

  commit('storeBooking', data);

  return data;
};

export const downloadBooking = async (_, bookingId) => {
  return await axiosWithJWT.get(`/api/Booking/Download/${bookingId}`, {
    responseType: 'blob',
  });
};

export const getBookings = async (_, filter) => {
  const { data } = await axiosWithJWT.get('/api/Booking/GetList', {
    params: filter,
  });
  return data;
};

export const update = async (_, booking) => {
  const { data } = await axiosWithJWT.put(
    `/api/Booking/Update/${booking.id}`,
    booking
  );
  return data;
};

export const changeBookingStatus = async (_, bookingId) => {
  await axiosWithJWT.put(`/api/Booking/ChangeStatus/${bookingId}`);
};

export const cancelBooking = async (_, bookingId) => {
  await axiosWithJWT.put(`/api/Booking/CancelBooking/${bookingId}`);
};

export const remove = async (_, bookingId) => {
  await axiosWithJWT.delete(`/api/Booking/Delete/${bookingId}`);
};

export const resetVouchersNum = async (_) => {
  await axiosWithJWT.put(`/api/Booking/ResetVouchersNum`);
};

export const updateVoucherNum = async (_, voucherData) => {
  await axiosWithJWT.put(`/api/Booking/UpdateVoucherNum`, voucherData);
};
