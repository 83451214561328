export default [
  {
    path: '/login',
    name: 'authentication-login',
    component: () => import('@/modules/authentication/views/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/forgot-password',
    name: 'authentication-forgot-password',
    component: () =>
      import('@/modules/authentication/views/ForgotPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/reset-password',
    name: 'authentication-reset-password',
    component: () => import('@/modules/authentication/views/ResetPassword.vue'),
    meta: {
      layout: 'full',
    },
  },
];
