import { axiosWithJWT } from '@/api/axiosWithJWT';

export const get = async ({ commit }, settingId) => {
  const { data } = await axiosWithJWT.get(
    `/api/GlobalSetting/Get/${settingId}`
  );

  commit('storeGlobalSettings', data);
  return data;
};

export const update = async (_, setting) => {
  await axiosWithJWT.put(`/api/GlobalSetting/Update/${setting.id}`, setting);
};
