export default [
  {
    path: '/frontier-tour-settings',
    name: 'frontier-tour-settings',
    component: () =>
      import('@/modules/frontier-tour-settings/views/FrontierTourSettings.vue'),
    meta: {
      pageTitle: 'Frontier Tour Settings',
    },
  },
];
