export default [
  {
    path: '/booking-settings',
    name: 'booking-settings',
    component: () =>
      import('@/modules/booking-settings/views/BookingFeeTypeList.vue'),
    meta: {
      pageTitle: 'Booking Settings',
    },
  },
];
