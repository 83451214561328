export default [
  {
    path: '/global-settings',
    name: 'setting-form',
    component: () => import('@/modules/setting/views/SettingForm.vue'),
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'Global Settings',
          active: true,
        },
      ],
    },
  },
];
