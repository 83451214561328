export default [
  {
    path: '/statistic/balances',
    name: 'statistic-balance',
    component: () => import('@/modules/statistic/views/Balance.vue'),
    meta: {
      pageTitle: 'Balance',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
    },
  },
  {
    path: '/statistic/standing-order',
    name: 'statistic-standing-order',
    component: () => import('@/modules/statistic/views/StandingOrderList.vue'),
    meta: {
      pageTitle: 'Standing Order',
      breadcrumb: [
        {
          text: 'Report',
          active: true,
        },
      ],
    },
  },
];
