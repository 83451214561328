import { axiosWithJWT } from '@/api/axiosWithJWT';

export const getBookingAttachmentList = async ({ commit }, bookingId) => {
  const { data } = await axiosWithJWT.get(
    `/api/BookingAttachment/GetBookingList/${bookingId}`
  );
  commit('storeBookingAttachments', data.attachments);
  return data;
};

export const uploadFiles = async (_, { bookingId, bookingAttachments }) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const formData = new FormData();

  bookingAttachments.forEach((file) => {
    formData.append('files', file);
  });

  await axiosWithJWT.post(
    `/api/BookingAttachment/UploadFiles/${bookingId}`,
    formData,
    {
      headers,
    }
  );
};

export const deleteFile = async (_, id) => {
  await axiosWithJWT.delete(`/api/BookingAttachment/Delete/${id}`);
};

export const downloadFile = async (_, id) => {
  return await axiosWithJWT.get(`/api/BookingAttachment/Get/${id}`, {
    responseType: 'blob',
  });
};
