import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';

import account from '@/modules/account/store/account';
import accountTransaction from '@/modules/account/store/account-transaction';
import authentication from '@/modules/authentication/store';
import booking from '@/modules/booking/store/booking';
import bookingAttachment from '@/modules/booking/store/bookingAttachment';
import bookingFeeType from '@/modules/booking-settings/store/';
import bookingSourceType from '@/modules/booking/store/bookingSourceType';
import bookingStatus from '@/modules/booking/store/bookingStatus';
import bookingType from '@/modules/booking/store/bookingType';
import company from '@/modules/company/store/company';
import companyReports from '@/modules/company/store/report';
import companyTransaction from '@/modules/company/store/company-transaction';
import crewRate from '@/modules/rate/store/crew';
import dashboard from '@/modules/dashboard/store';
import gibAirRate from '@/modules/rate/store/gib-air';
import poi from '@/modules/poi/store';
import post from '@/modules/post/store/post';
import postAttachment from '@/modules/post/store/tourAttachment';
import postStatus from '@/modules/post/store/post-status';
import setting from '@/modules/setting/store';
import staff from '@/modules/staff/store';
import standingOrder from '@/modules/taxi/store/standing-order';
import standingOrderType from '@/modules/standing-order-type/store';
import statistic from '@/modules/statistic/store';
import taxi from '@/modules/taxi/store/taxi';
import taxiBankAccount from '@/modules/taxi/store/bank-account';
import taxiJuraCard from '@/modules/taxi/store/jura-card';
import taxiTransaction from '@/modules/taxi/store/transaction';
import taxiVehicle from '@/modules/taxi/store/vehicle';
import tour from '@/modules/frontier-tour/store/tour';
import tourAttachment from '@/modules/frontier-tour/store/tourAttachment';
import tourMultiple from '@/modules/frontier-tour/store/multiple-tour';
import tourStatus from '@/modules/frontier-tour/store/tour-status';
import tourType from '@/modules/frontier-tour-settings/store';
import user from '@/modules/user/store';
import waitTimeRate from '@/modules/rate/store/wait-time';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    account,
    accountTransaction,
    authentication,
    booking,
    bookingAttachment,
    bookingFeeType,
    bookingSourceType,
    bookingStatus,
    bookingType,
    company,
    companyReports,
    companyTransaction,
    crewRate,
    dashboard,
    gibAirRate,
    poi,
    post,
    postAttachment,
    postStatus,
    setting,
    staff,
    standingOrder,
    standingOrderType,
    statistic,
    taxi,
    taxiBankAccount,
    taxiJuraCard,
    taxiTransaction,
    taxiVehicle,
    tour,
    tourAttachment,
    tourMultiple,
    tourStatus,
    tourType,
    user,
    waitTimeRate,
  },
  strict: process.env.DEV,
});
