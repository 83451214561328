export default [
  {
    path: '/taxis/list',
    name: 'taxi-list',
    component: () => import('@/modules/taxi/views/TaxiList.vue'),
    meta: {
      pageTitle: 'Taxis',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/taxis/transactions',
    name: 'taxi-transaction',
    component: () => import('@/modules/taxi/views/TaxiTransactionList.vue'),
    meta: {
      pageTitle: 'Taxis Transactions',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/taxis/:id/transactions',
    name: 'taxi-transaction-history',
    component: () => import('@/modules/taxi/views/TaxiTransactionsHistory.vue'),
    meta: {
      pageTitle: 'Taxis',
      breadcrumb: [
        {
          text: 'List',
          to: { name: 'taxi-list' },
        },
        {
          text: 'Taxi Transactions',
          active: true,
        },
      ],
    },
  },
  {
    path: '/taxis/:id',
    name: 'taxi-details',
    component: () => import('@/modules/taxi/views/TaxiDetails.vue'),
    meta: {
      pageTitle: 'Taxis',
      breadcrumb: [
        {
          text: 'List',
          to: { name: 'taxi-list' },
        },
        {
          text: 'Taxi Details',
          active: true,
        },
      ],
    },
  },
];
