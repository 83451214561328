import { axiosWithJWT } from '@/api/axiosWithJWT';

export const getList = async ({ commit }, filter) => {
  const { data } = await axiosWithJWT.get('/api/Company/GetList', {
    params: filter,
  });
  commit('storeCompanyList', data.companies);
  return data;
};

export const get = async (_, id) => {
  const { data } = await axiosWithJWT.get(`/api/Company/Get/${id}`);
  return data;
};

export const downloadAccountStatement = async (_, { companyId, params }) => {
  return await axiosWithJWT.post(
    `/api/Company/DownloadStatement/${companyId}`,
    params,
    {
      responseType: 'blob',
    }
  );
};

export const sendAccountStatement = async (_, { companyId, params }) => {
  await axiosWithJWT.post(`/api/Company/SendStatement/${companyId}`, params);
};

export const downloadBookingCompany = async (_, { companyId, params }) => {
  return await axiosWithJWT.post(
    `/api/Company/DownloadBookingCompany/${companyId}`,
    params,
    {
      responseType: 'blob',
    }
  );
};

export const downloadInvoiceCompany = async (_, { companyId, params }) => {
  return await axiosWithJWT.post(
    `/api/Company/DownloadInvoiceCompany/${companyId}`,
    params,
    {
      responseType: 'blob',
    }
  );
};

export const sendInvoiceCompany = async (_, { companyId, params }) => {
  await axiosWithJWT.post(
    `/api/Company/SendInvoiceCompany/${companyId}`,
    params
  );
};

export const sendBookingCompany = async (_, { companyId, params }) => {
  await axiosWithJWT.post(
    `/api/Company/SendBookingCompany/${companyId}`,
    params
  );
};

export const downloadPostCompany = async (_, { companyId, params }) => {
  return await axiosWithJWT.post(
    `/api/Company/DownloadPostCompany/${companyId}`,
    params,
    {
      responseType: 'blob',
    }
  );
};

export const sendPostCompany = async (_, { companyId, params }) => {
  await axiosWithJWT.post(`/api/Company/SendPostCompany/${companyId}`, params);
};

export const create = async (_, staff) => {
  const { data } = await axiosWithJWT.post('/api/Company/Create', staff);
  return data;
};

export const update = async (_, staff) => {
  await axiosWithJWT.put(`/api/Company/Update/${staff.id}`, staff);
};

export const remove = async (_, staffId) => {
  await axiosWithJWT.delete(`/api/Company/Delete/${staffId}`);
};
