export const storeBooking = (state, booking) => {
  state.booking = { ...booking };
};

export const resetBooking = (state) => {
  state.booking = {
    baggageNum: 0,
    bookedForDateUtc: null,
    bookingAdditionalFees: [],
    bookingAdditionalFeesTotalAmountToGta: 0.0,
    bookingAdjustmentFee: 0.0,
    bookingAttachments: [],
    bookingDateAddedUtc: null,
    bookingSourceType: {},
    bookingSourceTypeId: null,
    bookingTaxis: [
      {
        taxi: null,
        taxiRate: 0.0,
        vehicle: null,
        waitingTimeFee: 0.0,
        waitingTimeInMin: null,
        meterReaderFee: 0,
        heavyBaggageKg: 0,
        wheelchairNum: 0,
      },
    ],
    bookingType: {},
    bookingTypeId: null,
    bookingWaypointPois: [],
    company: {},
    companyContact: null,
    companyId: null,
    cruiseLinerVessel: null,
    dispatchedByStaffMember: {},
    dispatchedByStaffMemberId: null,
    dropoffPoiName: '',
    enforcePaxRate: false,
    ghaApprovalName: null,
    ghaPatientName: null,
    heavyBaggageKgTotalFee: 0,
    id: null,
    includeWaypointPois: false,
    issuedByStaffMember: {},
    issuedByStaffMemberId: null,
    isVoucherNumOverwritten: false,
    meterReaderTotalFee: 0,
    notes: null,
    crewMembers: null,
    officeFee: 0.0,
    passengerNum: 0,
    pickupPoiName: '',
    provideDetailedInvoice: false,
    totalBookingFee: 0.0,
    voucherNum: null,
    waitingTimeTotalFee: 0,
    wheelchairTotalFee: 0,
  };
};
