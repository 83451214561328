import { axiosWithJWT } from '@/api/axiosWithJWT';

export const getList = async ({ commit }, filter) => {
  const { data } = await axiosWithJWT.get('/api/Account/GetList', {
    params: filter,
  });
  commit('storeAccountList', data.accounts);
  return data;
};

export const get = async ({ commit }, id) => {
  const { data } = await axiosWithJWT.get(`/api/Account/Get/${id}`);
  commit('storeAccount', data);
  return data;
};

export const create = async (_, account) => {
  const { data } = await axiosWithJWT.post('/api/Account/Create', account);
  return data;
};

export const update = async (_, account) => {
  await axiosWithJWT.put(`/api/Account/Update/${account.id}`, account);
};

export const remove = async (_, accountId) => {
  await axiosWithJWT.delete(`/api/Account/Delete/${accountId}`);
};

export const downloadAccountStatement = async (_, { accountId, params }) => {
  return await axiosWithJWT.post(
    `/api/Account/DownloadStatement/${accountId}`,
    params,
    {
      responseType: 'blob',
    }
  );
};
