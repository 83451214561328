import { axiosWithJWT } from '@/api/axiosWithJWT';

export const getList = async (_, filter) => {
  const { data } = await axiosWithJWT.get('/api/StandingOrderType/GetList', {
    params: filter,
  });

  return data;
};

export const create = async (_, standingOrderType) => {
  const { data } = await axiosWithJWT.post(
    '/api/StandingOrderType/Create',
    standingOrderType
  );
  return data;
};

export const update = async (_, standingOrderType) => {
  await axiosWithJWT.put(
    `/api/StandingOrderType/Update/${standingOrderType.id}`,
    standingOrderType
  );
};

export const remove = async (_, standingOrderType) => {
  await axiosWithJWT.delete(
    `/api/StandingOrderType/Delete/${standingOrderType}`
  );
};
