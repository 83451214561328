import { axiosWithJWT } from '@/api/axiosWithJWT';

export const create = async (_, post) => {
  const { data } = await axiosWithJWT.post('/api/Post/Create', post);
  return data;
};

export const getPost = async ({ commit }, id) => {
  const { data } = await axiosWithJWT.get(`/api/Post/Get/${id}`);

  commit('storePost', data);

  return data;
};

export const downloadPost = async (_, postId) => {
  return await axiosWithJWT.get(`/api/Post/Download/${postId}`, {
    responseType: 'blob',
  });
};

export const getPosts = async (_, filter) => {
  const { data } = await axiosWithJWT.get('/api/Post/GetList', {
    params: filter,
  });
  return data;
};

export const changePostStatus = async (_, postId) => {
  await axiosWithJWT.put(`/api/Post/ChangeStatus/${postId}`);
};

export const update = async (_, post) => {
  const { data } = await axiosWithJWT.put(`/api/Post/Update/${post.id}`, post);
  return data;
};

export const remove = async (_, id) => {
  await axiosWithJWT.delete(`/api/Post/Delete/${id}`);
};
